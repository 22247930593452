import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { ProductionType } from '@/hooks/useConstructor';
import { CompanyModuleType, DebtorQuery } from '@/pages/debtors/_module/useDebtorsActions';
import { Ref } from 'vue';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useI18n } from 'vue-i18n';

type Options = {
  placeholder: string;
} & Record<any, any>

type Field = Omit<ActiveFormField<DebtorQuery>, 'options'> & {
  options: Options;
}

export function useMultifilter(
  module: Ref<ProductionType>,
) {
  const { t } = useLocalI18n('debtors');
  const { t: rootT } = useI18n();
  const { hasEnrichmentOptions } = useHasEnrichment();
  const {
    companyModuleType,
  } = useProtectedDefaultCompany();

  return {
    getMultifilter: () => (([
      {
        key: 'status',
        field: 'status',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.is_archived.placeholder'),
          options: [
            { value: 'disabled', label: t('filters.is_archived.yes') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      {
        key: 'has_enrichment',
        field: 'has_enrichment',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.has_enrichment.placeholder'),
          options: hasEnrichmentOptions,
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      {
        key: 'snils_exists',
        field: 'snils_exists',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.snils_exists.placeholder'),
          options: [
            { value: true, label: t('filters.snils_exists.has') },
            { value: false, label: t('filters.snils_exists.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      {
        key: 'is_bankrupt',
        field: 'is_bankrupt',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.is_bankrupt.placeholder'),
          options: [
            { value: 'yes', label: t('filters.is_bankrupt.yes') },
            { value: 'no', label: t('filters.is_bankrupt.no') },
            { value: 'unknown', label: t('filters.is_bankrupt.unknown') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      {
        key: 'unsend_into_billing',
        field: 'unsend_into_billing',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.unsend_into_billing.placeholder'),
          options: [
            { value: true, label: t('filters.unsend_into_billing.has') },
            { value: false, label: t('filters.unsend_into_billing.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      {
        key: 'court_type_latest',
        field: 'court_type_latest',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.court_type_latest.placeholder'),
          multiple: true,
          options: [
            { value: 'mirsud', label: t('filters.court_type_latest.mirsud') },
            { value: 'rajsud', label: t('filters.court_type_latest.rajsud') },
            { value: 'arbitr', label: t('filters.court_type_latest.arbitr') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      module.value !== ProductionType.pretrial && companyModuleType.value !== CompanyModuleType.Vehicles && {
        key: 'owner_has_enrichment',
        field: 'owner_has_enrichment',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.owner_has_enrichment.placeholder'),
          options: [
            { value: true, label: t('filters.owner_has_enrichment.has') },
            { value: false, label: t('filters.owner_has_enrichment.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 1,
        },
      },
      {
        key: 'is_organization',
        field: 'is_organization',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        width: 2,
        options: {
          placeholder: t('filters.is_organization.placeholder'),
          options: [
            { value: true, label: t('filters.is_organization.is_organization') },
            { value: false, label: t('filters.is_organization.!is_organization') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 2,
        },
      },
      companyModuleType.value === CompanyModuleType.Vehicles && {
        key: 'has_image',
        field: 'has_image',
        type: ActiveFormFieldType.select,
        width: 2,
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.image.placeholder'),
          options: [
            { value: true, label: t('filters.image.has') },
            { value: false, label: t('filters.image.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 3,
        },
      },
      module.value === ProductionType.pretrial && {
        key: 'has_sms',
        field: 'has_sms',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_sms'),
          options: [
            { value: true, label: rootT('pretrial.sms.sent') },
            { value: false, label: rootT('pretrial.sms.notSent') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 4,
        },
      },
      module.value === ProductionType.pretrial && {
        key: 'has_voice',
        field: 'has_voice',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_voice'),
          options: [
            { value: true, label: rootT('pretrial.voice.sent') },
            { value: false, label: rootT('pretrial.voice.notSent') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 5,
        },
      },
      module.value !== ProductionType.executive && {
        key: 'invoice',
        field: 'invoice',
        type: ActiveFormFieldType.select,
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.invoice.placeholder'),
          options: [
            { value: true, label: t('filters.invoice.has') },
            { value: false, label: t('filters.invoice.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 5,
        },
      },
      module.value !== ProductionType.pretrial && {
        key: 'doc_court_decision',
        field: 'doc_court_decision',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_court_decision.placeholder'),
          options: [
            { value: true, label: t('filters.doc_court_decision.has') },
            { value: false, label: t('filters.doc_court_decision.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 6,
        },
      },
      {
        key: 'doc_debtor',
        field: 'doc_debtor',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_debtor.placeholder'),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 7,
        },
      },
      {
        key: 'has_phone_number',
        field: 'has_phone_number',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_phone_number.placeholder'),
          options: [
            { value: true, label: t('filters.has_phone_number.has') },
            { value: false, label: t('filters.has_phone_number.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 7,
        },
      },
      module.value !== ProductionType.pretrial && module.value !== ProductionType.executive && companyModuleType.value !== CompanyModuleType.Vehicles && {
        key: 'is_verified_cad_num',
        field: 'is_verified_cad_num',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.is_verified_cad_num.placeholder'),
          options: [
            { value: true, label: t('filters.is_verified_cad_num.has') },
            { value: false, label: t('filters.is_verified_cad_num.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 8,
        },
      },
      {
        key: 'is_verified_result',
        field: 'is_verified_result',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.is_verified_result.placeholder'),
          options: [
            { value: true, label: t('filters.is_verified_result.has') },
            { value: false, label: t('filters.is_verified_result.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 8,
        },
      },
      module.value === ProductionType.judicial && {
        key: 'has_last_active_court_case_history_sync_date',
        field: 'has_last_active_court_case_history_sync_date',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_last_active_court_case_history_sync_date.placeholder'),
          options: [
            { value: true, label: t('filters.has_last_active_court_case_history_sync_date.has') },
            { value: false, label: t('filters.has_last_active_court_case_history_sync_date.hasNot') },
          ],
        },
      },
      module.value === ProductionType.judicial && {
        key: 'has_last_archive_court_case_history_sync_date',
        field: 'has_last_archive_court_case_history_sync_date',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_last_archive_court_case_history_sync_date.placeholder'),
          options: [
            { value: true, label: t('filters.has_last_archive_court_case_history_sync_date.has') },
            { value: false, label: t('filters.has_last_archive_court_case_history_sync_date.hasNot') },
          ],
        },
      },
      {
        key: 'has_registration_address',
        field: 'has_registration_address',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_registration_address.placeholder'),
          options: [
            { value: true, label: t('filters.has_registration_address.has') },
            { value: false, label: t('filters.has_registration_address.hasNot') },
          ],
        },
      },
      module.value === ProductionType.judicial && companyModuleType.value !== CompanyModuleType.Vehicles && {
        key: 'has_cad_num',
        field: 'has_cad_num',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_cad_num.placeholder'),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 8,
        },
      },
      {
        key: 'birth_date',
        field: 'birth_date',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t(`filters.${getFilterProfileModulePrefix(companyModuleType.value)}birth_date.placeholder`),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 9,
        },
      },
      {
        key: 'birth_place',
        field: 'birth_place',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t(`filters.${getFilterProfileModulePrefix(companyModuleType.value)}birth_place.placeholder`),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 10,
        },
      },
      {
        key: 'num_of_passport',
        field: 'num_of_passport',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t(`filters.${getFilterProfileModulePrefix(companyModuleType.value)}num_of_passport.placeholder`),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 10,
        },
      },
      {
        key: 'inn',
        field: 'inn',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t(`filters.${getFilterProfileModulePrefix(companyModuleType.value)}inn.placeholder`),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 10,
        },
      },
      module.value !== 'executive' && module.value !== ProductionType.pretrial
      && companyModuleType.value === CompanyModuleType.Communal && {
        key: 'owners',
        field: 'owners',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.owners.placeholder'),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 11,
        },
      },
      module.value !== 'executive' && module.value !== ProductionType.pretrial
      && companyModuleType.value === CompanyModuleType.Communal && {
        key: 'cohabitees',
        field: 'cohabitees',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.cohabitees.placeholder'),
          options: [
            { value: true, label: t('filters.doc_debtor.has') },
            { value: false, label: t('filters.doc_debtor.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 12,
        },
      },
      module.value !== 'executive' && module.value !== ProductionType.pretrial
      && companyModuleType.value === CompanyModuleType.Communal && {
        key: 'shareholders',
        field: 'shareholders',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.shareholders.placeholder'),
          options: [
            { value: true, label: t('filters.shareholders.has') },
            { value: false, label: t('filters.shareholders.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 12,
        },
      },
      companyModuleType.value === CompanyModuleType.Communal
      && module.value === ProductionType.judicial && {
        key: 'doc_house_register',
        field: 'doc_house_register',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_house_register.placeholder'),
          options: [
            { value: true, label: t('filters.doc_house_register.has') },
            { value: false, label: t('filters.doc_house_register.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 13,
        },
      },
      {
        key: 'track_number',
        field: 'track_number',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.track_number.placeholder'),
          options: [
            { value: true, label: t('filters.track_number.has') },
            { value: false, label: t('filters.track_number.hasNot') },
          ],
        },
      },
      companyModuleType.value === CompanyModuleType.Communal
      && module.value === ProductionType.judicial && {
        key: 'doc_protocol_ooc',
        field: 'doc_protocol_ooc',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_protocol_ooc.placeholder'),
          options: [
            { value: true, label: t('filters.doc_protocol_ooc.has') },
            { value: false, label: t('filters.doc_protocol_ooc.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 14,
        },
      },
      (module.value === ProductionType.judicial) && companyModuleType.value !== CompanyModuleType.Vehicles && {
        key: 'doc_payment',
        field: 'doc_payment',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_payment.placeholder'),
          options: [
            { value: true, label: t('filters.doc_payment.has') },
            { value: false, label: t('filters.doc_payment.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 15,
        },
      },
      module.value === ProductionType.executive && {
        key: 'has_bailiff',
        field: 'has_bailiff',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_bailiff.placeholder'),
          options: [
            { value: true, label: t('filters.has_bailiff.has') },
            { value: false, label: t('filters.has_bailiff.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 15,
        },
      },
      module.value === ProductionType.executive && {
        key: 'has_ifns',
        field: 'has_ifns',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_ifns.placeholder'),
          options: [
            { value: true, label: t('filters.has_ifns.has') },
            { value: false, label: t('filters.has_ifns.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 16,
        },
      },
      companyModuleType.value === CompanyModuleType.Communal
      && module.value === ProductionType.judicial && {
        key: 'doc_egrn',
        field: 'doc_egrn',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_egrn.placeholder'),
          options: [
            { value: true, label: t('filters.doc_egrn.has') },
            { value: false, label: t('filters.doc_egrn.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 17,
        },
      },
      companyModuleType.value === CompanyModuleType.Communal
      && module.value === ProductionType.judicial && {
        key: 'doc_egrn_rights',
        field: 'doc_egrn_rights',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.doc_egrn_rights.placeholder'),
          options: [
            { value: true, label: t('filters.doc_egrn_rights.has') },
            { value: false, label: t('filters.doc_egrn_rights.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 18,
        },
      },
      module.value !== ProductionType.pretrial && module.value !== ProductionType.executive && companyModuleType.value !== CompanyModuleType.Vehicles && {
        key: 'estate_owner_known',
        field: 'estate_owner_known',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.estate_owner_known.placeholder'),
          options: [
            { value: true, label: t('filters.estate_owner_known.has') },
            { value: false, label: t('filters.estate_owner_known.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 18,
        },
      },
      module.value === ProductionType.judicial && {
        key: 'has_judicial_by_magistrate',
        field: 'has_judicial_by_magistrate',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_judicial_by_magistrate.placeholder'),
          options: [
            { value: true, label: t('filters.has_judicial_by_magistrate.has') },
            { value: false, label: t('filters.has_judicial_by_magistrate.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 19,
        },
      },
      module.value === ProductionType.judicial && {
        key: 'has_judicial_by_regional',
        field: 'has_judicial_by_regional',
        type: ActiveFormFieldType.select,
        style: { marginTop: '10px' },
        isDropDownTripleSelect: true,
        options: {
          placeholder: t('filters.has_judicial_by_regional.placeholder'),
          options: [
            { value: true, label: t('filters.has_judicial_by_regional.has') },
            { value: false, label: t('filters.has_judicial_by_regional.hasNot') },
          ],
        },
        ionFieldGroup: {
          key: 'extra_fields',
          label: t('filterGroupLabels.extra_fields'),
          groupOrder: 999,
          order: 20,
        },
      },
    ].filter(Boolean) as Array<Field>).sort(sortAlphabetically)),
  };
}

function sortAlphabetically(a: Field, b: Field) {
  return a.options.placeholder.localeCompare(b.options.placeholder);
}

function getFilterProfileModulePrefix(moduleType: CompanyModuleType) {
  return moduleType === CompanyModuleType.Vehicles ? 'vehicles_' : '';
}

function useHasEnrichment() {
  const {
    t,
  } = useLocalI18n('exchange.export');

  const hasEnrichmentOptions = [
    {
      label: t('form.hasEnrichment.true'),
      value: 'success',
    },
    {
      label: t('form.hasEnrichment.false'),
      value: 'missing',
    },
    {
      label: t('form.hasEnrichment.unknown'),
      value: 'unsuccessfully',
    },
  ];

  return { hasEnrichmentOptions };
}
